import { QrcodeOutlined } from "@ant-design/icons";
import useSwitch from "@react-hook/switch";
import type { FC } from "react";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import type { CantataTypes } from "@/cantata/types";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { UserAssignmentQrcodeModal } from "@/resources/assignmentQrcode/UserAssignmentQrcodeModal";

const UserAssignmentQRCode: FC<{ userId: CantataTypes["User"]["id"] }> = ({
  userId,
}) => {
  const [open, toggle] = useSwitch(false);
  const orgId = useActiveOrgIdStore((state) => state.value);
  return (
    <div>
      <NarrowIconButton
        onClick={toggle.on}
        size={24}
        iconSize={16}
        icon={<QrcodeOutlined />}
      />
      {open && (
        <UserAssignmentQrcodeModal
          orgId={orgId}
          userId={userId}
          onCancel={toggle.off}
        />
      )}
    </div>
  );
};

export { UserAssignmentQRCode };
