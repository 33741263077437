import { EyeOutlined } from "@ant-design/icons";
import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { theme } from "@zeffiroso/theme";
import type { TableColumnProps as ColumnProps } from "antd";
import { Form } from "antd";
import { arrayMoveImmutable } from "array-move";
import { type FC, useMemo } from "react";
import type { DragDropContextProps } from "react-beautiful-dnd";
import { DragDropContext } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import { NarrowIconButton } from "@/components/Button/NarrowIconButton";
import { DndTable } from "@/components/Table/DndTable";
import { SubText } from "@/components/Typography";
import { MainLayout } from "@/layout/MainLayout";
import { AutoAssignmentRuleAssigneeTypeLabel } from "@/resources/autoAssignmentRule/assignee/AutoAssignmentRuleAssigneeTypeLabel";
import { TeamNameById } from "@/resources/team/TeamNameById";
import { UserNameById } from "@/resources/user/UserNameById";
import { routerUtils } from "@/router/routerUtils";
import type { ValidPathString } from "@/router/types";
import { compileToString } from "@/router/utils/compileTo";
import {
  useRoutingRules,
  useSwitchRulePriority,
} from "@/routes/Settings/Assignment/pages/RoutingRules/application/routingRules";
import { RuleDeleteButton } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RoutingRules/RuleDeleteButton";
import { RuleStatusSwitch } from "@/routes/Settings/Assignment/pages/RoutingRules/pages/RoutingRules/RuleStatusSwitch";
import { useUserPermission } from "@/shared/application/user";
import { EditOutlined as EditSvg } from "@/shared/icons/common/EditOutlined";

const Container = styled.div`
  width: 100%;
  max-width: 581px;
`;

const Description = styled(SubText)`
  display: block;
  margin-bottom: 16px;
`;

const MAX_AUTO_ASSIGNMENT_RULE_COUNT = 150;

const EditLinkButton = memo(function ({
  ruleId,
}: {
  ruleId: CantataTypes["AutoAssignmentRule"]["id"];
}) {
  const href = useMemo<ValidPathString>(
    () =>
      compileToString({
        pathname: "/settings/assignment/routing-rules/edit/:ruleId",
        params: {
          ruleId,
        },
      }),
    [ruleId],
  );
  return <NarrowIconButton icon={<EditSvg />} size={20} href={href} />;
});

const ViewLinkButton = memo(function ({
  ruleId,
}: {
  ruleId: CantataTypes["AutoAssignmentRule"]["id"];
}) {
  const href = useMemo<ValidPathString>(
    () =>
      compileToString({
        pathname: "/settings/assignment/routing-rules/view/:ruleId",
        params: {
          ruleId,
        },
      }),
    [ruleId],
  );
  return <NarrowIconButton icon={<EyeOutlined />} size={20} href={href} />;
});

function useColumns() {
  const { t } = useTranslation();
  const { hasPermission } = useUserPermission();
  const userCanEdit = hasPermission("editAutoAssignmentRule");
  return useMemo<ColumnProps<CantataTypes["AutoAssignmentRule"]>[]>(
    () => [
      {
        title: t("assignment.sorter"),
        render: userCanEdit
          ? DndTable.draggerColumnRender
          : (_value, _record, index) => index + 1,
      },
      {
        title: t("assignment.status"),
        dataIndex: "status",
        render: (status, rule) => {
          return (
            <RuleStatusSwitch
              ruleId={rule.id}
              status={status}
              // disabled=undefined will be treated as false
              {...(!userCanEdit ? { disabled: true } : null)}
            />
          );
        },
      },
      {
        title: t("assignment.ruleName"),
        dataIndex: "name",
      },
      {
        title: t("assignment.assignee"),
        dataIndex: ["assignee", "type"],
        render: (_, rule) => {
          switch (rule.assignee.type) {
            case "by-queue":
              return (
                <AutoAssignmentRuleAssigneeTypeLabel
                  type={rule.assignee.type}
                />
              );
            case "an-agent":
              return <UserNameById userId={rule.assignee.userId} />;
            case "a-team":
              return <TeamNameById teamId={rule.assignee.teamId} />;
            case null:
              return <AutoAssignmentRuleAssigneeTypeLabel type={null} />;
            default:
              rule.assignee satisfies never;
              throw new Error(
                inspectMessage`Unhandled assignee type: ${rule.assignee}`,
              );
          }
        },
      },
      {
        render: (rule: CantataTypes["AutoAssignmentRule"]) => {
          return (
            <Flex
              css={css`
                width: 56px;
                flex-direction: row;
                justify-content: flex-end;
                gap: 16px;

                svg {
                  color: ${theme.colors.neutral005};

                  &:hover,
                  &:active {
                    color: ${theme.colors.neutral006};
                  }

                  &:active {
                    color: ${theme.colors.neutral007};
                  }

                  &[disabled] {
                    color: ${theme.colors.neutral001};
                  }
                }
              `}
            >
              {userCanEdit && rule.status === "off" ? (
                <EditLinkButton ruleId={rule.id} />
              ) : (
                <ViewLinkButton ruleId={rule.id} />
              )}
              {!userCanEdit ? null : (
                <RuleDeleteButton ruleId={rule.id} ruleName={rule.name} />
              )}
            </Flex>
          );
        },
      },
    ],
    [t, userCanEdit],
  );
}

const RoutingRules: FC = () => {
  const { t } = useTranslation();
  const checkRoutePermission = routerUtils.useCheckRoutePermission();
  const userCanEdit = useMemo(
    () =>
      checkRoutePermission("/settings/assignment/routing-rules/edit/:ruleId"),
    [checkRoutePermission],
  );
  const routingRulesQuery = useRoutingRules();
  const columns = useColumns();
  const { isLoading: isSwitchRulePriorityLoading, switchRulePriority } =
    useSwitchRulePriority();

  const disableAddRuleButton =
    routingRulesQuery.data === undefined ||
    routingRulesQuery.data.autoAssignmentRules.length >
      MAX_AUTO_ASSIGNMENT_RULE_COUNT;

  const onDragEnd = useHandler<DragDropContextProps["onDragEnd"]>((event) => {
    // destination will be `null` when user drop draggable outside the droppable area
    if (
      !event.destination ||
      event.source.index === event.destination.index ||
      routingRulesQuery.data === undefined
    )
      return;

    const rulePriority = arrayMoveImmutable(
      routingRulesQuery.data.autoAssignmentRules,
      event.source.index,
      event.destination.index,
    ).map(({ id }) => id);

    switchRulePriority({ rulePriority });
  });

  return (
    <MainLayout.Item>
      <Container>
        <Description>{t("assignment.headlineDescription")}</Description>
        <Alert message={t("assignment.routingRules.desc")} />
      </Container>
      <Flex
        css={css`
          justify-content: flex-end;
          margin-bottom: 16px;
        `}
      >
        {!userCanEdit ? null : (
          <Button
            type="primary"
            href={compileToString("/settings/assignment/routing-rules/create")}
            disabled={disableAddRuleButton}
          >
            {t("assignment.add")}
          </Button>
        )}
      </Flex>
      <Form disabled={isSwitchRulePriorityLoading}>
        <DragDropContext onDragEnd={onDragEnd}>
          <DndTable
            data-test="rules-list-table"
            loading={routingRulesQuery.isLoading}
            rowKey="id"
            draggableId={(data) => data.id}
            draggerProps={(data) => ({
              "data-test": `rule-sortable-${data.id}`,
            })}
            columns={columns}
            dataSource={routingRulesQuery.data?.autoAssignmentRules}
            pagination={false}
            locale={{
              emptyText: t("common.noData"),
              emptyTextStyledCss: css`
                height: 366px;
              `,
            }}
          />
        </DragDropContext>
      </Form>
    </MainLayout.Item>
  );
};

export { RoutingRules };
