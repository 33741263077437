import { forwardRef } from "@chatbotgang/etude/react/forwardRef";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import type { SwitchProps } from "antd";
import { Switch } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useMergeFormDisabled } from "@/components/Form/DisabledContext";
import {
  useRoutingRules,
  useSwitchRuleStatus,
} from "@/routes/Settings/Assignment/pages/RoutingRules/application/routingRules";

export const RuleStatusSwitch = memo(
  forwardRef<
    HTMLElement,
    {
      ruleId: number;
      status: "on" | "off";
    } & Omit<SwitchProps, "checked" | "onChange" | "isLoading">
  >(function StatusSwitch({ status, ruleId, ...props }, ref) {
    const { t } = useTranslation();
    const { isLoading, mutate } = useSwitchRuleStatus({
      autoAssignmentRuleId: ruleId,
    });

    const handleUpdate = useHandler<SwitchProps["onChange"]>(
      (checked: boolean) => {
        const draftStatus = checked ? "on" : "off";
        mutate({ status: draftStatus });
      },
    );

    const routingRulesQuery = useRoutingRules();
    const mergeFormDisabled = useMergeFormDisabled();

    const disabled = useMemo(
      () =>
        mergeFormDisabled(
          props?.disabled ||
            isLoading ||
            (() => {
              if (!routingRulesQuery.isSuccess) return true;
              const currentRule =
                routingRulesQuery.data.autoAssignmentRules.find(
                  (rule) => rule.id === ruleId,
                );
              // Current rule not found, which is unexpected.
              if (!currentRule) return true;
              // Cannot enable a rule without an assignee
              return (
                currentRule.status === "off" &&
                currentRule.assignee.type === null
              );
            })(),
        ),
      [
        isLoading,
        mergeFormDisabled,
        props?.disabled,
        routingRulesQuery.data?.autoAssignmentRules,
        routingRulesQuery.isSuccess,
        ruleId,
      ],
    );

    return (
      <Switch
        data-test={`rule-status-switch-${ruleId}`}
        loading={isLoading}
        checked={status === "on"}
        onChange={handleUpdate}
        checkedChildren={t("glossary.switchOn")}
        unCheckedChildren={t("glossary.switchOff")}
        {...props}
        disabled={disabled}
        ref={ref}
      />
    );
  }),
);
