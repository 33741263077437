import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { safePromise } from "@chatbotgang/etude/safe/safePromise";
import { delay } from "@chatbotgang/etude/timer/delay";
import { css } from "@emotion/react";
import type { FC, ReactNode } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import type { FormProps } from "@/components/Form";
import { Form } from "@/components/Form";
import { useMessage } from "@/components/message";
import { MainLayout } from "@/layout/MainLayout";
import { SideMenuLayout } from "@/layout/SideMenuLayout";
import { routerUtils } from "@/router/routerUtils";
import { compileToString } from "@/router/utils/compileTo";
import { DescriptionField } from "@/routes/Settings/Teams/pages/TeamForm/form/fields/DescriptionField";
import { NameField } from "@/routes/Settings/Teams/pages/TeamForm/form/fields/NameField";
import { RoutingRuleField } from "@/routes/Settings/Teams/pages/TeamForm/form/fields/RoutingRuleField";
import { TeamUsersField } from "@/routes/Settings/Teams/pages/TeamForm/form/fields/TeamUsersField";
import { PageInfoProvider } from "@/routes/Settings/Teams/pages/TeamForm/usePageInfo";

const CreateFormContent: FC = () => {
  const { t } = useTranslation();
  return (
    <>
      <Flex
        css={css`
          justify-content: flex-end;
          margin-bottom: 16px;
          gap: 16px;
        `}
      >
        <Button href={compileToString("/settings/teams")}>
          {t("team.form.cancel")}
        </Button>
        <Button type="primary" htmlType="submit" data-test="create-team-button">
          {t("team.form.save")}
        </Button>
      </Flex>
      <NameField />
      <DescriptionField />
      <RoutingRuleField />
      <TeamUsersField />
    </>
  );
};

type FormValues = {
  name: string;
  description: string;
  routingRule: CantataTypes["Team"]["routingRule"];
  userIds: number[];
};

const initialValues: FormValues = {
  name: "",
  description: "",
  routingRule: "manual",
  userIds: [],
};

const FormProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormValues>();
  const message = useMessage();
  const navigate = routerUtils.useNavigate();
  const orgId = useActiveOrgIdStore((state) => state.value);

  const createMutation = cantata.team.useCreate({
    params: {
      orgId,
    },
  });

  const routerPromptOptions = useMemo<
    FormProps<FormValues>["routerPromptOptions"]
  >(() => {
    return {
      disabled: createMutation.isSuccess,
    };
  }, [createMutation.isSuccess]);

  const reset = useHandler(() => {
    createMutation.reset();
  });

  const onFinish = useHandler<FormProps<FormValues>["onFinish"]>(
    async (values) => {
      const { isSuccess } = await safePromise(() =>
        createMutation.mutateAsync(values),
      );

      if (!isSuccess) return;

      message.success(t("common.createSuccessfully"));

      // wait for router prompt to be disabled
      await delay(1);
      navigate("/settings/teams");
    },
  );

  return (
    <Form<FormValues>
      onFinish={onFinish}
      onValuesChange={() => {
        reset();
      }}
      form={form}
      layout="vertical"
      requiredMark={false}
      disabled={createMutation.isLoading}
      routerPromptOptions={routerPromptOptions}
      initialValues={initialValues}
    >
      {children}
    </Form>
  );
};

const TeamCreateForm: FC = () => {
  return (
    <PageInfoProvider>
      <FormProvider>
        <CreateFormContent />
      </FormProvider>
    </PageInfoProvider>
  );
};

const WrappedTeamCreateForm: FC = () => {
  const { t } = useTranslation();
  return (
    <SideMenuLayout.Layout>
      <MainLayout.Header
        breadcrumbItems={[
          {
            title: t("menu.team"),
            to: "/settings/teams",
          },
          {
            title: t("team.createPage.title"),
          },
        ]}
      >
        {t("team.createPage.title")}
      </MainLayout.Header>
      <MainLayout.Body>
        <MainLayout.Content>
          <MainLayout.Section>
            <TeamCreateForm />
          </MainLayout.Section>
        </MainLayout.Content>
      </MainLayout.Body>
    </SideMenuLayout.Layout>
  );
};

export { WrappedTeamCreateForm as TeamCreateForm };
