import { inspectMessage } from "@chatbotgang/etude/debug/inspectMessage";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useSwitch from "@react-hook/switch";
import { isEmpty, pick } from "lodash-es";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { EMPTY_STRING_PLACEHOLDER } from "@/appConstant";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Flex } from "@/components/Box";
import { Button } from "@/components/Button";
import type { FormProps } from "@/components/Form";
import { Form, FormItem } from "@/components/Form";
import { Input } from "@/components/Input";
import { BarLoading } from "@/components/Loading/BarLoading";
import { useMessage } from "@/components/message";
import { Select } from "@/components/Select";
import { BoldText, SubText } from "@/components/Typography";
import { parseMobile } from "@/lib/mobile";
import { UserAssignmentQrcodeModal } from "@/resources/assignmentQrcode/UserAssignmentQrcodeModal";
import { useFormatDateTime } from "@/resources/datetime";
import { TeamsSelector } from "@/resources/team/TeamsSelector";
import { routerUtils } from "@/router/routerUtils";
import { compileToString } from "@/router/utils/compileTo";
import { AvatarFormItem } from "@/routes/Settings/Profile/ui/AvatarFormItem";
import { CountryCodesItem } from "@/routes/Settings/Profile/ui/CountryCodesItem";
import { FormField } from "@/routes/Settings/Users/People/EditUser/FormField";
import { useUserInfo } from "@/shared/application/user";
import { isDefaultRoleType } from "@/shared/domains/role";
import { defaultRoleTypeTranslationKeyMap } from "@/shared/utils/translation/defaultRoleTypeTranslationKeyMap";

const StyledRoleWrap = styled(Flex)`
  flex-direction: column;
  margin-bottom: 16px;
  gap: 8px;
`;

const cssActionWrapper = css`
  display: flex;
  width: fit-content;
  margin-bottom: 12px;
  margin-left: auto;
  gap: 16px;
`;

type FormValues = {
  [key in keyof Pick<
    CantataTypes["UserDetail"],
    "avatar" | "chatName" | "mobile" | "name" | "roleId"
  >]: CantataTypes["UserDetail"][key];
} & {
  countryCode: string;
};

const pickUserMutationPayload = (member: CantataTypes["UserDetail"]) => {
  return pick(member, [
    "roleId",
    "name",
    "chatName",
    "mobile",
    "avatar",
    "status",
    "enableSoundNotification",
    "enablePopupNotification",
    "languageCode",
  ]);
};

type EditUserFormProps = {
  userId: number;
};

function selectUserDetail(values: CantataTypes["UserDetail"]) {
  const parseResult = parseMobile(values.mobile);
  return {
    ...values,
    mobile: parseResult.formattedNationalNumber,
    countryCode: parseResult.formattedCountryCode,
  };
}

export const EditUserForm = memo(function EditUserForm({
  userId,
}: EditUserFormProps) {
  const { t } = useTranslation();
  const message = useMessage();
  const navigate = routerUtils.useNavigate();
  const [form] = Form.useForm<FormValues>();
  const [userAssignmentQrcodeModalOpened, toggleUserAssignmentQrcodeModal] =
    useSwitch(false);
  const formatDateTime = useFormatDateTime();

  const orgId = useActiveOrgIdStore((state) => state.value);
  const userQuery = cantata.user.useGetById(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      select: selectUserDetail,
    },
  );

  const roleQuery = cantata.role.useListRoles({
    params: {
      orgId,
    },
  });

  const updateMutation = cantata.user.useUpdate(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      onSuccess: () => {
        message.success(t("common.updatedSuccessfully"));
        navigate("/settings/users");
      },
    },
  );

  const inviterQuery = cantata.user.useGetById(
    {
      params: {
        orgId,
        userId: userQuery.data?.inviterId ?? Number.NaN,
      },
    },
    {
      enabled: !!userQuery.data?.inviterId,
    },
  );

  const onFinish = useHandler((values: FormValues) => {
    if (!userQuery.data) return;

    const { countryCode, mobile, avatar, ...restValues } = values;

    let formattedNumber = "";
    if (!isEmpty(mobile)) {
      const parsed = parseMobile(`${countryCode}${mobile}`);

      if (!parsed.isNumberValid) {
        form.setFields([
          {
            name: "mobile",
            errors: [t("validation.invalidPhoneNumber")],
          },
        ]);

        return;
      }

      formattedNumber = parsed.formattedFullNumber;
    }

    // reset mobile field error
    form.setFields([
      {
        name: "mobile",
        errors: [],
      },
    ]);

    const payload = pickUserMutationPayload(userQuery.data);
    const nextValues = {
      ...payload,
      ...restValues,
      avatar: !avatar ? null : avatar,
      mobile: formattedNumber,
    };

    updateMutation.mutate(nextValues);
  });

  const onValuesChange = useHandler<FormProps<FormValues>["onValuesChange"]>(
    function onValuesChange() {
      updateMutation.reset();
    },
  );

  const me = useUserInfo();

  const roleIdFieldDisabled = useMemo(() => {
    if (!roleQuery.isSuccess || !userQuery.isSuccess) return true;

    const targetUserRoleType =
      roleQuery.data.roles.find((role) => role.id === userQuery.data.roleId)
        ?.type ?? "";

    return userId === me.id || targetUserRoleType === "owner";
  }, [
    me,
    userId,
    roleQuery.isSuccess,
    userQuery.isSuccess,
    roleQuery.data,
    userQuery.data,
  ]);

  if (userQuery.isLoading) return <BarLoading />;

  if (userQuery.isError)
    return <Alert type="error" message={inspectMessage`${userQuery.error}`} />;

  const user = userQuery.data;

  // FIXME: should use Form.RenderItem and enable routerPromptOptions
  return (
    <Form<FormValues>
      form={form}
      initialValues={userQuery.data}
      routerPromptOptions={{
        disabled: true,
      }}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      disabled={userQuery.isLoading || updateMutation.isLoading}
      scrollToFirstError
    >
      <div css={cssActionWrapper}>
        <Button href={compileToString("/settings/users")}>
          {t("common.cancel")}
        </Button>
        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            loading={updateMutation.isLoading}
          >
            {t("common.save")}
          </Button>
        </FormItem>
      </div>
      <FormField
        title={<BoldText>{t("myProfile.personalAvatar")}</BoldText>}
        content={
          <FormItem name="avatar">
            <AvatarFormItem type="user" orgId={orgId} userId={userId} />
          </FormItem>
        }
      />
      <FormField
        title={<BoldText>{t("common.name")}</BoldText>}
        content={
          <FormItem
            name="name"
            rules={[
              {
                required: true,
                message: t("validation.pleaseInputName"),
              },
              {
                max: 20,
                message: t("validation.maxCharLength", { count: 20 }),
              },
            ]}
          >
            <Input placeholder={user.name} />
          </FormItem>
        }
      />
      <FormField
        title={<BoldText>{t("common.displayName")}</BoldText>}
        content={
          <FormItem
            name="chatName"
            rules={[
              {
                required: true,
                message: t("validation.pleaseInputDisplayName"),
              },
              {
                max: 20,
                message: t("validation.maxCharLength", { count: 20 }),
              },
            ]}
          >
            <Input />
          </FormItem>
        }
      />
      <FormField
        title={<BoldText>{t("chat.mobile")}</BoldText>}
        content={
          <FormItem name="mobile">
            <Input
              $noSpinner={true}
              addonBefore={
                <FormItem name="countryCode" noStyle>
                  <CountryCodesItem />
                </FormItem>
              }
              type="number"
            />
          </FormItem>
        }
      />
      <FormField
        title={<BoldText>{t("glossary.email")}</BoldText>}
        content={
          <FormItem>
            <Input disabled value={user.email} />
          </FormItem>
        }
      />
      <FormField
        title={<BoldText>{t("myProfile.roles")}</BoldText>}
        content={
          <StyledRoleWrap>
            <FormItem name="roleId" noStyle>
              {roleQuery.isLoading ? (
                <Select loading={roleQuery.isLoading} disabled />
              ) : roleQuery.isError ? (
                <Alert
                  type="error"
                  message={inspectMessage`${roleQuery.error}`}
                />
              ) : (
                <Select
                  disabled={roleIdFieldDisabled}
                  options={roleQuery.data.roles.map((role) => ({
                    key: role.id,
                    value: role.id,
                    disabled: role.type === "owner",
                    label: isDefaultRoleType(role.type) ? (
                      <SubText>
                        {t(defaultRoleTypeTranslationKeyMap[role.type])}
                      </SubText>
                    ) : (
                      <SubText>{role.name}</SubText>
                    ),
                  }))}
                />
              )}
            </FormItem>
            <SubText>
              {t("myProfile.createTimeHint", {
                date: formatDateTime(user.createdAt),
              })}
            </SubText>
            <SubText>
              {t("myProfile.inviterHint", {
                name: inviterQuery.isSuccess
                  ? inviterQuery.data.name
                  : EMPTY_STRING_PLACEHOLDER,
              })}
            </SubText>
          </StyledRoleWrap>
        }
      />
      <FormField
        title={<BoldText>{t("myProfile.team")}</BoldText>}
        content={
          <FormItem>
            <TeamsSelector
              value={userQuery.data.teams.map((team) => team.id)}
              disabled
            />
          </FormItem>
        }
      />
      <FormField
        title={<BoldText>{t("myProfile.qrCode")}</BoldText>}
        content={
          <FormItem>
            <Button type="primary" onClick={toggleUserAssignmentQrcodeModal.on}>
              {t("myProfile.createQRCode")}
            </Button>
            {!userAssignmentQrcodeModalOpened ? null : (
              <UserAssignmentQrcodeModal
                orgId={orgId}
                userId={userId}
                onCancel={toggleUserAssignmentQrcodeModal.off}
              />
            )}
          </FormItem>
        }
      />
      <FormField
        title={t("myProfile.permanentAssignmentCount")}
        content={<span>{user.permanentAssignmentMembersCount}</span>}
      />
    </Form>
  );
});
