import { DownOutlined } from "@ant-design/icons";
import { memo } from "@chatbotgang/etude/react/memo";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import useSwitch from "@react-hook/switch";
import { theme } from "@zeffiroso/theme";
import { isAxiosError } from "axios";
import { pick } from "lodash-es";
import { useTranslation } from "react-i18next";

import { useActiveOrgIdStore } from "@/activeOrgId/store";
import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Flex } from "@/components/Box";
import { Dropdown } from "@/components/Dropdown";
import { defineItems } from "@/components/Menu/util";
import { useMessage } from "@/components/message";
import { Modal } from "@/components/Modal";
import { UserStatusLabel } from "@/resources/user/UserStatus";
import { useUserInfo } from "@/shared/application/user";
import { handleNonGlobalApiError } from "@/shared/domains/error";

export const UpdateUserStatus = memo<{
  userId: CantataTypes["User"]["id"];
  status: CantataTypes["User"]["status"];
}>(function UpdateUserStatus({ userId, status }) {
  const { t } = useTranslation();
  const [open, toggle] = useSwitch(false);
  const message = useMessage();
  const orgId = useActiveOrgIdStore((state) => state.value);
  const me = useUserInfo();
  const meId = me?.id ?? Number.NaN;
  const userQuery = cantata.user.useGetById(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      enabled: status === "invited" || userId !== meId || open,
    },
  );

  const updateMutation = cantata.user.useUpdate(
    {
      params: {
        orgId,
        userId,
      },
    },
    {
      onSuccess: toggle.off,
      onError: async (err) => {
        const handled = await handleNonGlobalApiError(err, {
          AUTO_ASSIGNMENT_RULE_ASSIGNEE_AN_AGENT_EXISTED: () => {
            message.error(t("organization.suspendUserFailed.modal.content"));
          },
        });
        if (handled || (isAxiosError(err) && err.status === 404)) {
          toggle.off();
        }
      },
    },
  );

  const handleUpdateStatus = useHandler(
    (status: CantataTypes["User"]["status"]) => {
      if (!userQuery.data) return;

      const draft = {
        ...pick(userQuery.data, [
          "roleId",
          "name",
          "chatName",
          "mobile",
          "avatar",
          "enableSoundNotification",
          "enablePopupNotification",
          "languageCode",
        ]),
        status,
      };

      updateMutation.mutate(draft);
    },
  );

  const operationNorPermitted = status === "invited" || userId === meId;

  return (
    <>
      <Dropdown
        menu={{
          items: defineItems([
            status === "active"
              ? {
                  key: "suspend",
                  label: <UserStatusLabel status="suspend" />,
                  onClick: toggle.on,
                  "data-test": "user-status-suspend-item",
                }
              : {
                  key: "active",
                  label: <UserStatusLabel status="active" />,
                  onClick: toggle.on,
                  "data-test": "user-status-active-item",
                },
          ]),
        }}
        disabled={operationNorPermitted}
      >
        <Flex
          css={css`
            align-items: center;
            gap: 8px;
          `}
        >
          <UserStatusLabel status={status} />
          {!operationNorPermitted && (
            <DownOutlined style={{ color: theme.colors.neutral007 }} />
          )}
        </Flex>
      </Dropdown>
      <Modal
        open={open}
        onCancel={toggle.off}
        cancelText={t("common.cancel")}
        okText={t("common.confirm")}
        title={status !== "active" ? t("common.enable") : t("common.suspend")}
        onOk={() =>
          handleUpdateStatus(status !== "active" ? "active" : "suspend")
        }
        okButtonProps={{
          loading: updateMutation.isLoading,
        }}
      >
        {status !== "active"
          ? t("organization.activateUser.confirmText")
          : t("organization.deactivateUser.confirmText")}
      </Modal>
    </>
  );
});
