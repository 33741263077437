import type { ComponentProps } from "@chatbotgang/etude/react/ComponentProps";
import { createContext } from "@chatbotgang/etude/react/createContext";
import { useHandler } from "@chatbotgang/etude/react/useHandler";
import { css } from "@emotion/react";
import type { Overwrite } from "@mui/types";
import { theme } from "@zeffiroso/theme";
import { createQueriesContext } from "@zeffiroso/utils/react-query/createQueriesContext";
import { type FC, type ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { cantata } from "@/cantata";
import type { CantataTypes } from "@/cantata/types";
import { Alert } from "@/components/Alert";
import { Button } from "@/components/Button";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { HighlightedCopyInput } from "@/components/Input";
import { Modal } from "@/components/Modal";
import type { QrCode } from "@/components/QrCode";
import { QrCodeBlock } from "@/components/QrCode/QrCodeBlock";
import { ChannelSelect } from "@/components/Select/ChannelSelect";
import { defineStyles } from "@/shared/emotion";
import { useLocaleCompare } from "@/shared/hooks/useLocaleCompare";

namespace ParamsContext {
  export interface Props {
    orgId: CantataTypes["Org"]["id"];
    userId: CantataTypes["User"]["id"];
  }
}

const ParamsContext = createContext<ParamsContext.Props>({
  name: "ParamsContext",
});

function useUserAssignmentQrcodeQueries() {
  const options = ParamsContext.useContext();
  const channelsQuery = cantata.channel.useList(
    {
      params: {
        orgId: options.orgId,
      },
    },
    {
      suspense: true,
      useErrorBoundary: true,
      select: (data) => data.channels,
    },
  );
  const userQrcodesQuery =
    cantata.assignmentQrcode.useListUserAssignmentQrcodes(
      {
        params: {
          orgId: options.orgId,
          userId: options.userId,
        },
      },
      {
        suspense: true,
        useErrorBoundary: true,
        select: (data) => data.assignmentQrcodes,
      },
    );
  const queries = useMemo(
    () => ({
      channels: channelsQuery,
      userQrcodes: userQrcodesQuery,
    }),
    [channelsQuery, userQrcodesQuery],
  );
  return queries;
}

const UserAssignmentQrcodeQueries =
  createQueriesContext<ReturnType<typeof useUserAssignmentQrcodeQueries>>();

const QueriesContextProvider: FC<{
  children: ReactNode;
}> = (props) => {
  const queries = useUserAssignmentQrcodeQueries();
  return (
    <UserAssignmentQrcodeQueries.Provider queries={queries}>
      {props.children}
    </UserAssignmentQrcodeQueries.Provider>
  );
};
namespace Content {
  export interface Props
    extends Omit<ComponentProps<typeof Modal>, "children" | "footer"> {}
}

const styles = defineStyles({
  modalContent: css({
    width: "100%",
    maxWidth: 340,
    paddingTop: 8,
    paddingBottom: 26,
    margin: "0 auto",
  }),
  descriptionBlock: css({
    display: "flex",
    flexDirection: "column",
    padding: 10,
    marginBottom: 8,
  }),
  title: css({
    marginBottom: 8,
    color: theme.colors.neutral009,
    fontSize: "0.875rem",
    fontWeight: 500,
  }),
  desc: css({
    marginBottom: 16,
    color: theme.colors.neutral007,
    fontSize: "0.875rem",
    fontWeight: 400,
  }),
  row: css({
    marginBottom: 20,
  }),
  alignCenterBox: css({
    display: "flex",
    justifyContent: "center",
  }),
  qrCodeWrapper: css({
    padding: "16px 0",
    marginBottom: 10,
  }),
});

const extendedStyles = defineStyles({
  qrcodeWrapper: css([
    styles.alignCenterBox,
    {
      padding: "16px 0",
      marginBottom: 10,
    },
  ]),
});

const Content: FC<Content.Props> = () => {
  const { t } = useTranslation();
  const { channels, userQrcodes } = UserAssignmentQrcodeQueries.useData();
  const localeCompare = useLocaleCompare();
  const channelsWithQrCode = useMemo(
    () =>
      channels
        .filter((channel) =>
          userQrcodes.some((qrCode) => qrCode.channel.id === channel.id),
        )
        .toSorted((a, b) => localeCompare(a.name, b.name)),
    [channels, localeCompare, userQrcodes],
  );

  const [channelId, setChannelId] = useState<CantataTypes["Channel"]["id"]>(
    Number.NaN,
  );
  const channel = useMemo<
    undefined | NonNullable<typeof channels>[number]
  >(() => {
    if (!channelId) return undefined;
    return channelsWithQrCode.find((channel) => channel.id === channelId);
  }, [channelId, channelsWithQrCode]);
  const showQRCode = !Number.isNaN(channelId);
  const [qrCodeImperativeHandle, setQrCodeImperativeHandle] =
    useState<QrCode.ImperativeHandle | null>(null);
  const url = useMemo(() => {
    if (!channelId) return;
    return userQrcodes.find((userQrcode) => userQrcode.channel.id === channelId)
      ?.qrcodeUrl;
  }, [channelId, userQrcodes]);

  const download = useHandler(function download() {
    if (!qrCodeImperativeHandle || !channel) return;

    qrCodeImperativeHandle.download(channel.name);
  });

  return (
    <div css={styles.modalContent}>
      <div css={styles.descriptionBlock}>
        <div css={styles.title}>{t("myProfile.qrCodeChannel")}</div>
        <div css={styles.desc}>{t("myProfile.qrCodeModal.desc")}</div>
        <ChannelSelect
          channels={channelsWithQrCode}
          value={channel?.id ?? Number.NaN}
          onChange={setChannelId}
          placeholder={t("myProfile.selectChannel")}
        />
      </div>
      {!showQRCode ? null : !url ? (
        <div css={styles.row}>
          <Alert
            type="error"
            message="LINE channel's line id is not provide."
          />
        </div>
      ) : (
        <>
          <div css={styles.row}>
            <div css={extendedStyles.qrcodeWrapper}>
              <QrCodeBlock
                style={{ width: 160 }}
                text={url}
                imperativeHandleRef={setQrCodeImperativeHandle}
              />
            </div>
            <HighlightedCopyInput value={url} />
          </div>
          <div css={styles.alignCenterBox}>
            <Button
              type="primary"
              disabled={!showQRCode || !url}
              onClick={download}
            >
              {t("myProfile.downloadQRCode")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

namespace UserAssignmentQrcodeModal {
  export interface Props
    extends Overwrite<
      Omit<ComponentProps<typeof Modal>, "children" | "footer">,
      ParamsContext.Props
    > {}
}

const UserAssignmentQrcodeModal: FC<UserAssignmentQrcodeModal.Props> = ({
  orgId,
  userId,
  ...props
}) => {
  const params = useMemo(
    () => ({
      orgId,
      userId,
    }),
    [orgId, userId],
  );
  const { t } = useTranslation();
  const open = useMemo(
    () =>
      !userId
        ? /**
           * If `userId` is falsy, modal is closed.
           */
          false
        : "open" in props
          ? /**
             * Props `open` overrides default behavior.
             */
            props.open
          : /**
             * Default behavior is to open the modal if `userId` is truthy.
             */
            Boolean(userId),
    [props, userId],
  );

  return (
    <ParamsContext.Provider value={params}>
      <Modal
        title={t("myProfile.createQRCode")}
        destroyOnClose
        {...props}
        open={open}
        footer={null}
      >
        <ErrorBoundary.Alert>
          <QueriesContextProvider>
            <Content />
          </QueriesContextProvider>
        </ErrorBoundary.Alert>
      </Modal>
    </ParamsContext.Provider>
  );
};

export { UserAssignmentQrcodeModal };
